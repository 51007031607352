<template>
  <div class="card" @click="redirect">
    <div v-if="isNotif" class="notification">
      <p>{{ numberOfNotifications }}</p>
    </div>
    <!-- <div class="card_image">  </div> -->
    <div class="card_title title-white d-flex flex-column align-items-center">
      <span
        class="iconify my-2"
        :data-icon="link"
        data-inline="false"
        data-width="70"
        data-height="70"
      ></span>
      <span class="card-title">{{ title }}</span>
    </div>
    <!-- <div type="button" class="btn btn-light" @click="redirect">
       Check Out
    </div> -->
  </div>
</template>
<script>
export default {
  name: "SmallCard",
  props: ["title", "link", "address", "isNotif", "numberOfNotifications"],
  methods: {
    redirect() {
      this.address;
      console.log(this.address);
      this.$router.push({
        path: this.address
      });
    }
  }
};
</script>
<style scoped>
.card {
  margin: 30px auto;
  width: 260px;
  height: 260px;
  border-radius: 40px;
  box-shadow: 10px 10px 2px rgba(110, 0, 0, 0.25);
  background: white;
  cursor: pointer;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale();
}
.card-title {
  font-size: 22px;
}

.card .card_image {
  width: inherit;
  height: inherit;
  border-radius: 40px;
}

.card .card_image img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 20px;
}

.card .card_title {
  position: absolute;
  text-align: center;
  padding: 5px;
}

.card:hover {
  transform: scale(0.9, 0.9);
  border-radius: 50px;
  box-shadow: 10px 10px 5px 6px rgba(110, 0, 0, 0.25);
}

.title-white {
  color: rgb(148, 22, 0);
  font-size: 20px;
  font-weight: 900;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.title-black {
  color: black;
}
.btn {
  width: 100px;
  margin: auto;
}
a {
  color: black;
}
.notification {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  background-color: pink;
  opacity: 0.8;
  border-radius: 50%;

  top: 0;
}
.notification > p {
  text-align: center;
  font-weight: bold;
}
</style>
